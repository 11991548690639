export default function useGoogleMapsGeocoder() {
    let geocoder = null;

    async function initGeocoder() {
        if (!geocoder) {
            // Initialize geocoder if it hasn't been already
            const { Geocoder } = await google.maps.importLibrary('geocoding');
            geocoder = new Geocoder();
        }
    }

    async function getAddressByLatLng(latitude, longitude) {
        await initGeocoder();
        try {
            const response = await geocoder.geocode({
                location: { lat: latitude, lng: longitude },
            });

            const { results } = response;
            if (results && results.length > 0) {
                return getAllAddressData(latitude, longitude, results);
            }
            return null;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async function searchAddress(address, countryCode, bounds) {
        await initGeocoder();
        try {
            const response = await geocoder.geocode({
                address: address,
                componentRestrictions: {
                    country: countryCode,
                },
                bounds: bounds,
            });

            const { results } = response;
            return results;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    function getAllAddressData(latitude, longitude, results) {
        var locality = null,
            commune = null,
            county = null,
            street = null,
            country = null,
            sector = null,
            streetNumber = null;
        var c, lc, component, t, lt;
        var localityOrCommune = null;

        for (var r = 0, rl = results.length; r < rl; r += 1) {
            var result = results[r];

            if (result.types[0] === 'locality') {
                for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                    component = result.address_components[c];

                    if (component.types[0] === 'locality') {
                        locality = component.long_name;
                        break;
                    }
                }
            } else if ((!sector || !commune) && result.types[2] === 'sublocality_level_1') {
                for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                    component = result.address_components[c];
                    if (component.types.indexOf('sublocality_level_1') != -1) {
                        if (component.long_name.indexOf('Sector') != -1) {
                            sector = component.long_name; // Sector
                        } else {
                            commune = component.long_name;
                            // A commune is also a type of locality
                            localityOrCommune = component.long_name;
                        }
                    }
                }
            } else if (result.types[0] === 'administrative_area_level_2') {
                for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                    component = result.address_components[c];
                    if (component.types[0] === 'administrative_area_level_2') {
                        if (!locality) {
                            locality = component.long_name; // Locality
                        } else if (!commune) {
                            commune = component.long_name; // Commune
                        }

                        break;
                    }
                }
            } else if (!county && result.types[0] === 'administrative_area_level_1') {
                for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                    component = result.address_components[c];
                    if (component.types[0] === 'administrative_area_level_1') {
                        county = component.long_name; // County
                        break;
                    }
                }
            } else if (!street && (result.types[0] === 'route' || result.types[0] === 'street_address')) {
                for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                    component = result.address_components[c];
                    if (component.types[0] === 'street_address' || component.types[0] === 'route') {
                        street = component.long_name;
                        break;
                    }
                    for (t = 0, lt = result.address_components[c].types.length; t < lt; t += 1) {
                        if (component.types[t] === 'route' || result.types[t] === 'street_address') {
                            streetNumber = component.long_name;
                            break;
                        }
                    }
                }
            } else if (!country && result.types[0] === 'country') {
                for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                    component = result.address_components[c];
                    if (component.types[0] === 'country') {
                        country = component.long_name;
                        break;
                    }
                }
            }
        }

        if (localityOrCommune && !sector && !locality) {
            locality = localityOrCommune;
        }

        if (!!country && country.toLowerCase().startsWith('rom') && (!!locality || !!commune)) {
            return {
                formatted_address: parseAddress({
                    street: street,
                    number: streetNumber,
                    commune: commune,
                    sector: sector,
                    locality: locality,
                    county: county,
                    country: country,
                }),
                street: street,
                number: streetNumber,
                sector: !!sector ? parseSectorName(sector) : null,
                commune: !!commune ? parseLocalityName(commune) : null,
                locality: locality ? parseLocalityName(locality) : parseLocalityName(commune),
                county: parseCountyName(county),
                country: country,
                latitude: latitude,
                longitude: longitude,
                types: results[0]['types'],
            };
        } else {
            return null;
        }
    }

    function parseAddress(data) {
        if (data.street == 'Unnamed Road') {
            data.street = null;
        }
        if (data.number != null) {
            data.number = 'nr. ' + data.number;
        }
        if (data.commune != null && data.commune.indexOf('Municipiul') == 0) {
            data.commune = null;
        }
        var address = '';
        for (var i in data) {
            if (data[i] != null) {
                data[i] = removeDiacritics(data[i]);
                address += data[i] + ', ';
            }
        }
        address = address.substring(0, address.length - 2);
        address = address.replace(/bucharest/gi, 'Bucuresti');

        return address;
    }

    function parseSectorName(str) {
        str = parseString(str);
        str = str.replace(/sectorul |sectoru /gi, 'sector ');
        str = str.replace(/bucharest |bucuresti /gi, '');

        return str;
    }

    function parseCountyName(str) {
        str = parseString(str);
        str = str.replace(/^(judetul |judetu |orasul |oras |municipiul |municipiu )/gi, '');
        str = str.replace(/bucharest/gi, 'bucuresti');

        return str;
    }

    function parseLocalityName(str) {
        str = parseString(str);
        str = str.replace(/^(comuna |satul |orasul |oras |municipiul |municipiu )/gi, '');
        str = str.replace(/bucharest/gi, 'bucuresti');

        return str;
    }

    function parseString(str) {
        if (!str) {
            return null;
        }
        str = str.trim();
        str = str.toLowerCase();
        str = removeDiacritics(str);
        str = str.replace(/&[a-z]+;/gi, match => match[1]);

        return str;
    }

    function removeDiacritics(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    return {
        getAddressByLatLng,
        searchAddress,
    };
}
