import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';
import useGoogleMapsGeocoder from '@resources/js/app/composables/useGoogleMapsGeocoder.js';

const { getAddressByLatLng, searchAddress } = useGoogleMapsGeocoder();
let self;

export default ($wire, $dispatch, { mapDomContainer, searchBarContainer }) => ({
    map: null,
    mapDom: null,
    mapLoader: null,
    searchBar: null,
    marker: null,
    infoWindow: null,
    trans: $wire.entangle('translations'),
    address: $wire.entangle('address').live,
    listingAddress: $wire.entangle('listingAddress'),
    gpsPosition: $wire.entangle('gpsPosition'),
    mapApiKey: $wire.entangle('mapApiKey').live,
    zoom: $wire.entangle('defaultMapZoom').live,
    center: $wire.entangle('center').live,
    canInteractWithMap: false,

    init() {
        self = this;
        self.searchBar = document.getElementById(searchBarContainer);
        self.mapDom = document.getElementById(mapDomContainer);

        document.addEventListener('livewire:init', () => {
            // Set map area height based on device viewable screen height
            self.mapDom.style.height =
                window.innerHeight - (self.searchBar.offsetHeight + self.searchBar.parentNode.offsetTop) + 'px';
        });

        self.mapLoader = new GoogleMapsLoader({ apiKey: self.mapApiKey, libraries: ['places'], language: ['ro'] });
        self.mapLoader.load().then(async () => {
            const { Map, InfoWindow } = await google.maps.importLibrary('maps');
            self.infoWindow = new InfoWindow({ content: '' });

            const map = (self.map = new Map(self.mapDom, {
                zoom: self.zoom,
                center: { lat: self.center.lat, lng: self.center.lng },
                mapTypeId: 'roadmap',
                gestureHandling: 'greedy',
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: true,
                mapId: 'create_edit_listing_map',
            }));

            self.marker = await self.initMarker(map);
            self.initSearchBarAutocomplete();
        });

        if (self.listingAddress) {
            self.disableOverlay();
        } else if (self.gpsPosition.latitude && self.gpsPosition.longitude) {
            self.setGpsLocation(parseFloat(self.gpsPosition.latitude), parseFloat(self.gpsPosition.longitude));
        }

        window.addEventListener('setLocation', event => {
            self.setGpsLocation(parseFloat(event.detail.lat), parseFloat(event.detail.lng));
        });
    },

    async initMarker(map) {
        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
        let draggableMarker = new AdvancedMarkerElement({
            position: { lat: self.center.lat, lng: self.center.lng },
            map,
            gmpDraggable: true,
        });

        self.map.addListener('click', async e => {
            draggableMarker.position = e.latLng;
            await self.setAddressFromMarkerPosition(e.latLng.lat(), e.latLng.lng());
        });

        draggableMarker.addListener('dragend', async e => {
            const position = draggableMarker.position;
            await self.setAddressFromMarkerPosition(position.lat, position.lng);
        });

        return draggableMarker;
    },

    async setGpsLocation(lat, lng) {
        self.address = await self.setAddressFromMarkerPosition(lat, lng);
        self.marker.position = { lat: lat, lng: lng };
        self.submitLocation();
        self.disableOverlay();
    },

    getMarkerPosition() {
        return self.marker ? new google.maps.LatLng(self.marker.position.lat, self.marker.position.lng) : null;
    },

    async handlePlaceChanged(place) {
        if (!place.geometry) {
            return;
        }
        self.map.fitBounds(place.geometry.viewport);
        self.map.setCenter(place.geometry.location);
        self.map.setZoom(self.getZoomLevelForTypes(place.types));
        self.marker.position = place.geometry.location;
        self.address = self.listingAddress = [];
        self.disableOverlay();
    },

    initSearchBarAutocomplete() {
        var options = {
            location: self.getMarkerPosition(),
            radius: 20000, // in meters.
            types: ['geocode'],
            componentRestrictions: { country: 'ro' },
            fields: ['formatted_address', 'geometry', 'name'],
        };

        const autoComplete = new google.maps.places.Autocomplete(self.searchBar, options);
        autoComplete.addListener('place_changed', async e => {
            self.handlePlaceChanged(autoComplete.getPlace());
        });
    },

    async submitSearch() {
        if (self.searchBar.value.length < 2) {
            return;
        }
        const results = await searchAddress(self.searchBar.value, 'RO', self.map.getBounds());
        if (results[0]?.place_id === 'ChIJw3aJlSb_sUARlLEEqJJP74Q') {
            return;
        }
        self.handlePlaceChanged(results[0]);
    },

    async setAddressFromMarkerPosition(latitude, longitude) {
        try {
            const addresObject = await getAddressByLatLng(latitude, longitude);
            if (!!addresObject) {
                self.map.setCenter({ lat: latitude, lng: longitude });
                self.map.setZoom(self.getZoomLevelForTypes(addresObject.types));
                self.address = addresObject;
                self.searchBar.value = addresObject.formatted_address;
                $wire.clearErrors();
                return addresObject;
            } else {
                alert(this.trans['location_cant_find_error']);
                self.searchBar.value = '';
            }
        } catch (e) {
            alert(this.trans['location_geocode_error']);
            self.searchBar.value = '';
        }
    },

    submitLocation() {
        if (self.isEmpty(self.address) && !self.isEmpty(self.listingAddress)) {
            $dispatch('toggle-location-modal', 'close');
            return false;
        }

        $wire.call('setLocation');
    },

    getZoomLevelForTypes(types) {
        if (!types || !types.length) {
            return 14; //default
        }

        for (var r = 0, rl = types.length; r < rl; r += 1) {
            var type = types[r];
            if (type === 'administrative_area_level_1') {
                //county
                return 8;
            }
            if (type === 'administrative_area_level_2') {
                //commune
                return 13;
            }
            if (type === 'locality') {
                //city
                return 12;
            }
            if (type === 'neighborhood') {
                //zone
                return 14;
            }
            if (type === 'route') {
                //street
                return 15;
            }
            if (type === 'country') {
                return 6;
            }
        }

        return 14; //default
    },

    isEmpty(collection) {
        return Array.isArray(collection) ? !collection.length : !Object.keys(collection).length;
    },

    disableOverlay() {
        self.canInteractWithMap = true;
    },
});
